import React, { ReactElement } from 'react'

import styles from './FooterStyles.module.scss'

type Props = {
  links: Array<ReactElement>
}

export default function Footer({ links }: Props) {
  return (
    <footer className={styles.footer}>
      <div className={styles['links']}>{links.map((link) => link)}</div>

      <p className={styles.disclaimer}>
        Monzo Bank Limited is a company registered in England and Wales (No.
        09446231). Monzo Bank Ltd is authorised by the Prudential Regulation
        Authority (PRA) and regulated by the Financial Conduct Authority and the
        PRA. Our Financial Services Register number is 730427.
      </p>
      <nav className={styles['main-nav']} aria-label="Monzo links">
        <ul className={styles['footer-links']}>
          <li>
            <a href="https://monzo.com/blog">Blog</a>
          </li>
          <li>
            <a href="https://monzo.com/community">Community</a>
          </li>
          <li>
            <a href="https://monzo.com/careers">Careers</a>
          </li>
          <li>
            <a href="https://monzo.com/press">Press</a>
          </li>
          <li>
            <a href="https://monzo.com/docs">API</a>
          </li>
        </ul>
      </nav>

      <nav className={styles.social} aria-label="Social links">
        <ul className={styles['footer-links']}>
          <li>
            <a
              href="https://twitter.com/monzo"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/assets/twitter.svg" alt="Twitter" />
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/monzobank"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/assets/facebook.svg" alt="Facebook" />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/monzo-bank"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/assets/linkedin.svg" alt="LinkedIn" />
            </a>
          </li>
          <li>
            <a
              href="mailto:hello@monzo.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/assets/mail.svg" alt="Drop us an email" />
            </a>
          </li>
        </ul>
      </nav>
    </footer>
  )
}
