import getConfig from 'next/config'
import store from 'store2'

import { urlEncodeObject } from './utils'

const { publicRuntimeConfig: env } = getConfig()

const accessTokenKey = 'api.access_token'
const refreshTokenKey = 'api.refresh_token'

const storage = store.namespace('monzo').session

// ------------------------------------
// Private functions
// ------------------------------------

const fetchApi = (
  requestUrl: string,
  init: RequestInit,
  responseType: string,
) =>
  fetch(requestUrl, init)
    .catch((error) => {
      console.error(`Network error: ${error.message}`)
      return Promise.reject({ success: false })
    })
    .then((response) =>
      (responseType === 'json' ? response.json() : response.text()).then(
        (body: any) => ({ body, response }),
      ),
    )
    .then(({ body, response }) =>
      response.ok
        ? Promise.resolve({ success: true, body })
        : Promise.reject({
            success: false,
            errorCode: body.code,
            errorMessage: body.message,
          }),
    )

/**
 * This library contains methods to access Monzo's public APIs
 */
class MondoApi {
  trackAnalyticsEvent = (
    body: Record<string, any>,
    headers: Record<string, any>,
  ): Promise<{
    success: boolean
    body?: any
    errorCode?: string
    errorMessage?: string
  }> => {
    return this.fetchApi('/analytics/track', {
      headers,
      method: 'POST',
      body: JSON.stringify(body),
    })
  }

  fetchPayment = (code: string) => this.doPOST('/pay-anyone/read', { code })

  claimPaymentByEmail = (code: string, email: string) =>
    this.doPUT('/pay-anyone/claim-monzo', { code, email })

  claimPaymentByFPS = (
    code: string,
    sort_code: string,
    account_number: string,
    name: string,
    email: string,
  ) =>
    this.doPUT('/pay-anyone/claim-fps', {
      code,
      sort_code,
      account_number,
      name,
      email,
    })

  fetchInPayAnyoneEmailExperiment = (
    code: string,
  ): Promise<{
    body: {
      variant: string
    }
  }> => this.doPUT(`/pay-anyone/read-fps-claim-email-experiment`, { code })

  authClient = () =>
    this.doPOST('/oauth2/token', {
      grant_type: 'client_credentials',
      client_id: env.apiClientId,
      client_secret: env.apiClientSecret,
    })

  refreshAccessToken = (refreshToken: string) =>
    this.doPOST('/oauth2/token', {
      grant_type: 'refresh_token',
      client_id: env.apiClientId,
      client_secret: env.apiClientSecret,
      refresh_token: refreshToken,
    })

  // ------------------------------------
  // Auth helper methods
  // ------------------------------------

  getAccessToken = () => storage.get(accessTokenKey, null)
  getRefreshToken = () => storage.get(refreshTokenKey, null)

  setAccessToken = (accessToken: string | null) =>
    storage.set(accessTokenKey, accessToken)
  setRefreshToken = (refreshToken: string | null) =>
    storage.set(refreshTokenKey, refreshToken)

  clearAccessToken = () => storage.remove(accessTokenKey)
  clearRefreshToken = () => storage.remove(refreshTokenKey)

  isAuthed = () => !!this.getAccessToken()

  getAuth = () => ({
    accessToken: this.getAccessToken(),
    refreshToken: this.getRefreshToken(),
  })

  setAuth = (session: {
    access_token: string | null
    refresh_token: string | null
  }) => {
    this.setAccessToken(session.access_token || null)
    this.setRefreshToken(session.refresh_token || null)
  }

  clearAuth = () => {
    this.clearAccessToken()
    this.clearRefreshToken()
  }

  // ------------------------------------
  // Helper methods
  // ------------------------------------

  monzoApiURL = (path: string) => env.apiBaseURL + path

  fetchApi = (path: string, init: RequestInit) => {
    const accessToken = this.getAccessToken()
    init.headers = {
      __auth_v2: 'true',
      Authorization: accessToken ? `Bearer ${accessToken}` : '',
      ...init.headers,
    }

    return fetchApi(this.monzoApiURL(path), init, 'json')
  }

  doGET = (path: string, initOverwrites: RequestInit) =>
    this.fetchApi(path, {
      method: 'GET',
      ...initOverwrites,
    })

  doPOST = (
    path: string,
    body: Record<string, any>,
    initOverwrites?: RequestInit,
  ): Promise<any> =>
    this.fetchApi(path, {
      method: 'POST',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
        Accept: 'application/json',
      },
      body: urlEncodeObject(body),
      ...initOverwrites,
    })

  doPUT = (
    path: string,
    body: Record<string, any>,
    initOverwrites?: RequestInit,
  ): Promise<any> =>
    this.fetchApi(path, {
      method: 'PUT',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
        Accept: 'application/json',
      },
      body: urlEncodeObject(body),
      ...initOverwrites,
    })

  doDELETE = (
    path: string,
    body: Record<string, any>,
    initOverwrites: RequestInit,
  ) =>
    this.fetchApi(path, {
      method: 'DELETE',
      ...initOverwrites,
    })
}

/**
 * Generator function to create a configurable MondoApi instance.
 * @returns {MondoApi}
 */
export function createMondoApi() {
  return new MondoApi()
}

export default createMondoApi()
