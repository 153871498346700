import mondoApi from './mondoSdk'

export const authClient = async () => {
  await mondoApi.clearAuth()
  const result = await mondoApi.authClient()
  if (result && result.success) {
    await mondoApi.setAuth(result.body)
    return result
  }
}

export const apiRequest = async (fn: any, ...args: any[]) => {
  const isAuthed = await mondoApi.isAuthed()
  if (!isAuthed) {
    await authClient()
  }

  try {
    return await fn(...args)
  } catch (e) {
    const error = e as globalThis.Error & {
      success: boolean
      errorCode: string
    }
    const isAuthError =
      error &&
      !error.success &&
      error.errorCode &&
      (error.errorCode.startsWith('unauthorized.bad_access_token') ||
        error.errorCode === 'unauthorized')
    if (isAuthError) {
      await authClient()
      return await fn(...args)
    }
    throw error
  }
}
