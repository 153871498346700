export function sanitizeURL(url: string): string {
  if (typeof window === 'undefined') {
    return ''
  }
  const pathname = window.location.pathname
  const partsOfPath = pathname.split('/')
  let sanitizedURL = url
  // We iterate over all parts of the path and
  // sanitize anything that's not a known path name.
  for (let i = 0; i < partsOfPath.length; i++) {
    switch (partsOfPath[i]) {
      case 'billing':
      case 'pay':
      case 'amount':
      case '':
        // We skip the actual path names.
        break
      default:
        // We replace anything that's not a known path name
        // - e.g. description, payment request ID, username.
        // @ts-ignore TODO: fix this
        sanitizedURL = sanitizedURL.replace(partsOfPath[i], '[REMOVED]')
    }
  }
  return sanitizedURL
}
