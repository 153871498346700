import 'what-input'

import '../styles/index.scss'
import '../styles/static_styles.css'

// Required for setting CSP headers in getInitialProps in _document
import { NextPageContext } from 'next'
import getConfig from 'next/config'
import Head from 'next/head'
import Router from 'next/router'
import React from 'react'
import ReactGA from 'react-ga'

import { displayExternalSelfXSSWarning } from '@mondough/self-xss-warning'

import { AppContextProvider } from '../AppContext'
import { Layout } from '../components/Layout/index'
import { initializeServerSideLogging } from '../utils/customLog'

const { publicRuntimeConfig: env } = getConfig()

interface AppProps {
  Component: React.ComponentType
  pageProps: any
}

function App({ Component, pageProps }: AppProps) {
  React.useEffect(() => {
    // Set Google Analytics key
    ReactGA.initialize(env.googleAnalyticsTrackingId)
    const recordPageview = (path: string) => {
      ReactGA.set({ page: path })
      ReactGA.pageview(path)
    }
    Router.events.on('routeChangeComplete', recordPageview)
    recordPageview(window.location.pathname)
  }, [])

  initializeServerSideLogging()

  if (typeof window !== 'undefined') {
    displayExternalSelfXSSWarning()
  }

  return (
    <AppContextProvider>
      <Head>
        <title>Receive money using Monzo</title>
        <meta
          name="viewport"
          content="width=device-width, height=device-height, initial-scale=1, maximum-scale=5"
        />
      </Head>
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </AppContextProvider>
  )
}

App.getInitialProps = async ({
  Component,
  ctx,
}: {
  Component: any
  ctx: NextPageContext
}) => {
  let pageProps = {}
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx)
  }

  return { pageProps }
}

export default App
