import c from 'classnames'
import React from 'react'

import Button from '../Button'
import styles from './Help.module.scss'

type Props = {
  onDone: (event: React.SyntheticEvent<HTMLButtonElement>) => unknown
}

const Help = ({ onDone }: Props) => {
  return (
    <div role="dialog" aria-live="assertive" aria-labelledby="helpHeader">
      <h2 id="helpHeader" className={c(styles['center'])}>
        Pay People via Text or Email
      </h2>
      <div className={c(styles['center'])}>
        <img
          className={c(styles['pay-anyone'])}
          src="/assets/pay-anyone.png"
          alt=""
        />
      </div>
      <p>Someone on Monzo wants to send you some money.</p>

      <h3>What is Monzo?</h3>
      <p>
        A bank that lives in your phone. We’re a fully regulated bank in the UK.{' '}
        <a href="https://monzo.com">Find out more.</a>
      </p>
      <p>
        With Monzo, you can send money to anyone just by sharing a link. You
        don’t need to know their bank details.
      </p>

      <h3>Receiving this money</h3>
      <p>
        As the recipient, you can either open a Monzo account to get the money,
        or put it in any UK bank account by telling us your sort code and
        account number.
      </p>
      <p>
        If you choose to send the money to another bank account, it will be sent
        via Faster Payments and usually arrives instantly.
      </p>
      <Button buttonStyle="primary" onClick={onDone} ariaLabel="Close help">
        Got it
      </Button>
    </div>
  )
}

export default Help
