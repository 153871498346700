import * as React from 'react'

import ErrorBanner from '../ErrorBanner'
import Footer from '../Footer'
import Header from '../Header'
import Help from '../Help'
import styles from './LayoutStyles.module.scss'

type Props = {
  children: React.ReactNode
}

const Layout = ({ children }: Props) => {
  const [showHelp, setShowHelp] = React.useState(false)

  const toggleHelp = () => {
    window.scrollTo(0, 0)
    setShowHelp(!showHelp)
  }

  return (
    <div className={styles.container}>
      <Header showHelp={showHelp} toggleHelp={toggleHelp} />
      <ErrorBanner />
      <main className={styles.wrapper}>
        {showHelp ? <Help onDone={toggleHelp} /> : children}
      </main>
      <Footer
        links={[
          <a href="https://monzo.com/legal/privacy-notice" key="privacy">
            Privacy notice
          </a>,
          <a href="https://monzo.com/legal/cookie-notice" key="cookie">
            Cookie notice
          </a>,
        ]}
      />
    </div>
  )
}

export default Layout
