import * as React from 'react'

import { useAppContext } from '../../AppContext'
import { resetError } from '../../utils/errors'
import styles from './ErrorBanner.module.scss'

const ErrorBanner = () => {
  const { errors, setErrors } = useAppContext()
  const { fatal, defaultMessage } = errors || {}

  const handleDismissError = (event: React.MouseEvent<unknown>) => {
    event.preventDefault()

    if (fatal) {
      window.location.reload()
    } else {
      resetError(setErrors)
    }
  }
  return errors ? (
    <div className={styles.banner} role="alert">
      <span>{defaultMessage}</span>
      <button className={styles.dismiss} onClick={handleDismissError}>
        {fatal ? 'Reload page' : 'Dismiss'}
      </button>
    </div>
  ) : null
}

export default ErrorBanner
