import cx from 'classnames'
import * as React from 'react'

import styles from './Button.module.scss'

type Props = JSX.IntrinsicElements['button'] & {
  buttonStyle?: string
  loading?: boolean
  href?: string
  ariaLabel?: string
  children?: React.ReactNode | null | undefined
}

export default function Button({
  buttonStyle,
  disabled,
  loading,
  className,
  href,
  type,
  ariaLabel,
  onClick,
  onTouchStart,
  onTouchEnd,
  onMouseDown,
  onMouseUp,
  children,
}: Props) {
  let classNames = [styles['default']]
  if (buttonStyle === 'primary') {
    classNames = [styles['primary']]
  } else if (buttonStyle === 'primary-small') {
    classNames = [styles['primary-small']]
  }
  if (disabled) {
    classNames.push(styles['disabled'])
  }
  if (loading) {
    classNames.push(styles['loading'])
  }

  if (href != null) {
    return (
      <a className={cx(classNames, className)} href={href}>
        {children}
      </a>
    )
  }

  return (
    <button
      className={cx(classNames, className)}
      onClick={onClick}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      disabled={disabled}
      type={type}
      aria-label={ariaLabel != null ? ariaLabel : undefined}
    >
      {loading ? <div className={styles.loader} /> : children}
    </button>
  )
}
