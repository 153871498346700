import React from 'react'

import styles from './Header.module.scss'

type Props = {
  showHelp: boolean
  toggleHelp: () => void
}

export default function Header({ showHelp, toggleHelp }: Props) {
  return (
    <header className={styles['monzo-container']}>
      <a href="https://monzo.com" target="_blank" rel="noopener noreferrer">
        <h1>
          <img
            src="/assets/monzo.logo.svg"
            className={styles.logo}
            alt="Monzo"
          />
        </h1>
      </a>
      <button
        className={styles[showHelp ? 'close' : 'help']}
        onClick={toggleHelp}
        aria-label={showHelp ? 'Close help' : 'Show help'}
        aria-expanded={showHelp}
      />
    </header>
  )
}
