import type { Breadcrumb, Event as SentryEvent } from '@sentry/browser'
const DEFAULT_BLOCKLIST = ['email', 'code', 'auth_code']

// We have to use the type 'mixed' here because the flow-typed definitions for Sentry can only guarantee
// that the `data` object that contains our URLs has the type `[string]: mixed`.
const sanitizeURL = (
  url?: string,
  blocklist?: string[],
  customURLSanitizer: (arg0: string) => string = (url) => url,
): URL | null | undefined => {
  const paramsToSanitize = [...(blocklist ?? []), ...DEFAULT_BLOCKLIST]

  if (typeof url !== 'string') {
    return
  }

  try {
    const urlWithCustomSanitization = customURLSanitizer(url)
    // Generate a new URL object from our sanitised href. Use the current URL origin as a base
    // just in case we're dealing with a relative URL (e.g. '/my-path'), so that we can still
    // generate and return a valid URL.
    const newUrl = new URL(urlWithCustomSanitization, window.location.origin)
    paramsToSanitize.forEach((blockedKey) => {
      const params = newUrl.searchParams

      if (params == null || !params.has(blockedKey)) {
        return
      }

      params.set(blockedKey, 'REMOVED')
    })
    return newUrl
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.warn(
        'URL not sanitised because unable to construct URL object from:',
        url,
      )
    }
  }
}

/*
 * The sanitizers are partially-applied functions which may take a list of blocklisted params
 * which will then be sanitized out of the data logged to Sentry. Or a custom URL sanitiser, which will
 * be run on URL before going through the standard sanitization.
 * Returns: a function which expects a SentryEvent (or Breadcrumb in the case of sanitizeBreadcrumb).
 * If you don't have any particular blocklisting requirements, you can just call the function with no args.
 * Make sure you call the function when you pass it in to `setup`.
 */
export const sanitizeEvent =
  (blocklist?: string[], customURLSanitizer?: (arg0: string) => string) =>
  (event: SentryEvent): SentryEvent => {
    if (event.request == null) {
      return event
    }

    const sanitizedURL = sanitizeURL(
      event.request.url,
      blocklist,
      customURLSanitizer,
    )
    const sanitizedHeaders = event.request?.headers
    const referer = event.request?.headers?.['Referer']

    if (sanitizedHeaders != null && referer != null) {
      const sanitizedReferer = sanitizeURL(
        referer,
        blocklist,
        customURLSanitizer,
      )

      if (sanitizedReferer?.href != null) {
        sanitizedHeaders['Referer'] = sanitizedReferer.href
      }
    }

    const sanitizedException = {
      ...event,
      request: {
        ...event.request,
        url: sanitizedURL?.href,
        headers: sanitizedHeaders,
      },
    }
    return sanitizedException
  }
export const sanitizeBreadcrumb =
  (blocklist?: string[], customURLSanitizer?: (arg0: string) => string) =>
  (breadcrumb: Breadcrumb) => {
    if (breadcrumb.data == null) {
      return breadcrumb
    }

    switch (breadcrumb.category) {
      case 'navigation':
        const fromPath = breadcrumb.data['from']
        const toPath = breadcrumb.data['to']
        const sanitizedFromPath = sanitizeURL(
          fromPath,
          blocklist,
          customURLSanitizer,
        )
        const sanitizedToPath = sanitizeURL(
          toPath,
          blocklist,
          customURLSanitizer,
        )
        return {
          ...breadcrumb,
          data: {
            ...breadcrumb.data,
            from:
              sanitizedFromPath != null
                ? `${sanitizedFromPath.pathname}${sanitizedFromPath.search}`
                : fromPath,
            to:
              sanitizedToPath != null
                ? `${sanitizedToPath.pathname}${sanitizedToPath.search}`
                : toPath,
          },
        }

      case 'fetch':
        return {
          ...breadcrumb,
          data: {
            ...breadcrumb.data,
            url: sanitizeURL(
              breadcrumb.data['url'],
              blocklist,
              customURLSanitizer,
            ),
          },
        }

      default:
        return breadcrumb
    }
  }
