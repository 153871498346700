import React, { createContext, useContext, useEffect, useState } from 'react'
import { ReactNode } from 'react'

import { Error } from './types/error'
import { Experiments } from './types/experiments'
import { Payment } from './types/payment'
import { fetchPayment } from './utils/payment'

export const extractHashFromUrl = (url: string): string => {
  const decodedUrl = new URL(decodeURIComponent(url))
  return decodedUrl.hash.substring(1)
}

const AppContext = createContext({
  code: '',
  setCode: (_value: string) => {
    return
  },
  status: '',
  setStatus: (_value: string) => {
    return
  },
  errors: null as Error | null,
  setErrors: (_value: Error | null) => {
    return
  },
  payment: undefined as Payment | undefined,
  setPayment: (_value: Payment) => {
    return
  },
  experiments: {
    email: {
      requestEmail: false,
      showEmailOptIn: false,
      emailRequired: false,
    },
  } as Experiments,
  setExperiments: (_value: Experiments) => {
    return
  },
})

export function AppContextProvider({ children }: { children: ReactNode }) {
  const [code, setCode] = useState('')
  const [status, setStatus] = useState('')
  const [errors, setErrors] = useState<Error | null>(null)
  const [payment, setPayment] = useState<Payment>()
  const [experiments, setExperiments] = useState<Experiments>({
    email: null,
  })

  useEffect(() => {
    const fetchOnMount = async () => {
      const code = extractHashFromUrl(window?.location.href)
      setCode(code)
      if (!payment) {
        await fetchPayment(code, setStatus, setPayment, setErrors)
      }
    }
    fetchOnMount()
  }, [payment, setPayment])

  return (
    <AppContext.Provider
      value={{
        code,
        setCode,
        status,
        setStatus,
        errors,
        setErrors,
        payment,
        setPayment,
        experiments,
        setExperiments,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export function useAppContext() {
  return useContext(AppContext)
}
