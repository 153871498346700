/* This lives in its own library so that apps don't have to import lib.utils if they only need this. */
const envIsProd = () => {
  // split this out into two parts, as the web apps will always have NODE_ENV of 'production' regardless of the backend env it's connected to.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const monzoEnv = (window as any)?.Monzo?.env.env
  if (monzoEnv != null) return monzoEnv === 'production'

  // our next.js apps don't have a monzoEnv so we still need to check for NODE_ENV.
  const processEnv = process?.env?.NODE_ENV
  if (processEnv != null)
    return processEnv != null && processEnv === 'production'

  return false
}

export const displayExternalSelfXSSWarning = () => {
  if (envIsProd()) {
    console.log('%cHold on!', `color: red; font-weight: bold; font-size: 2rem;`)
    console.log(
      `%cThis feature is meant for developers, so please don't paste or type any code in here unless you're sure you know what you're doing. If someone's told you to paste code in, it's probably a scam which will give them access to your personal info – be careful!`,
      `color: black; font-size: 1rem; line-height: 1.5rem;`,
    )
  }
}

export const displayInternalSelfXSSWarning = () => {
  // we don't want to show the warning in any env other than prod
  if (envIsProd()) {
    console.log('%cHold on!', `color: red; font-weight: bold; font-size: 2rem;`)
    console.log(
      `%cThis feature is meant for developers, so please don't paste or type any code in here unless you're sure you know what you're doing. If someone's told you to paste code in, it's probably a scam which will give them access to sensitive data – be careful!`,
      `color: black; font-size: 1rem; line-height: 1.5rem;`,
    )
  }
}
