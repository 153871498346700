import type { SentryEventFilter } from '../types'
import { envIsProd } from '../utils'

export enum SAMPLING_RATE_BY_FREQUENCY {
  HIGH = 0.01, // 1%: More than 10 errors per day
  MEDIUM = 0.1, // 10%:  More than 1 error error per week
  LOW = 0.25, // 25%: Less than 1 error per week
  NO_SAMPLING = 1, // 100%
}

/*
The following hardcoded list of GraphQL operations are known async 
issues which should be resolved, we're sampling these specific logs 
to reduce noise, whilst maintaining visibility of these sentry events.

Each known noisy GraphQL operation's sample rate has been determiend by it's frequency over a 90 day period
*/

// More than 10 errors per day
const highFrequencyValues = new Map([
  ['listAnnouncements', ''],
  ['sendHeartbeat', ''],
  ['logBizopsMemoryUsage', ''],
  ['openBizopsEscalationsByTaskId', ''],
  ['paymentsByAccount', ''],
  ['escalationSummary', ''],
  ['bizopsEmailThreadNotes', ''],
  ['bizopsEmailThread', ''],
  ['listMembers', ''],
  ['reachOutsInProgress', ''],
  ['getUserAnalyticsFeatures', ''],
  ['getSupportAuthenticationStatus', ''],
  ['listReachOutTypes', ''],
  ['disputesNote', ''],
])

// More than 1 error error per week
const mediumFrequencyValues = new Map([
  ['listNotices', ''],
  ['accountById', ''],
  ['listPots', ''],
  ['getMonzoIBAN', ''],
  ['getBacsPaymentsForAccount', ''],
  ['openBizopsTasksByUserId', ''],
  ['suspensePayment', ''],
  ['bizOpsReachOuts', ''],
  ['rewardAccounts', ''],
  ['dynamicFormResponse', ''],
  ['listCustomerEvents', ''],
  ['fetchActiveTaskView', ''],
  ['schemeData', ''],
  ['getEmailThread', ''],
  ['monzoFlexTransactionEligibility', ''],
  ['listFraudProtectInvestigationsWithDetails', ''],
  ['getVisibilityResult', ''],
  ['profilePrivacySettingsForUser', ''],
  ['ReadChequesForAccount', ''],
  ['getSupportCallByExternalId', ''],
  ['latestCustomerRiskAssessments', ''],
  ['featureFlagsByUserId', ''],
  ['loanContexts', ''],
  ['getVisibilityResults', ''],
  ['customerProfile', ''],
  ['bizopsEmailAttachmentsByUserId', ''],
  ['transactionsByAccountId', ''],
  ['getAnnouncement', ''],
  ['webletsByType', ''],
  ['bannedOrRestrictedAccessSupportalBanner', ''],
  ['cardsOrdersAndAccountForUser', ''],
  ['fetchTaskViews', ''],
  ['gamblingBlock', ''],
  ['getSimilarMessages', ''],
  ['debtSale', ''],
  ['supportIDVRecord', ''],
  ['primaryUsageStatusByCustomerId', ''],
  ['BusinessMembers', ''],
  ['cifasCases', ''],
  ['listCustomerNotes', ''],
  ['customerExit', ''],
  ['getRewardsEscalation', ''],
  ['getUserPresence', ''],
  ['getComplaints', ''],
])

// Known noisy error, but less than 1 error per week
const lowFrequencyValues = new Map([
  ['ReadChequeDetails', ''],
  ['customerCreateDateById', ''],
  ['activePaidPlan', ''],
  ['checkProactiveChatClosureEnabled', ''],
  ['readDocuments', ''],
  ['currentBusinessMemberSignup', ''],
  ['customerForbearanceHistory', ''],
  ['fraudInvestigation', ''],
  ['setActiveTaskView', ''],
  ['controlPoints', ''],
  ['getPreviousAddressesByOwner', ''],
  ['getCardOrderAddresses', ''],
  ['vulnerabilitySupportalFlagsByUserId', ''],
  ['productLabels', ''],
  ['getUserPIN', ''],
  ['listBizopsTaskQueues', ''],
  ['financialDifficultyPanelData', ''],
  ['readRFI', ''],
  ['accountTakeoverUserStatus', ''],
  ['transactionDisputeForm', ''],
  ['getTransferwiseUser', ''],
  ['fetchTags', ''],
  ['notableBehaviors', ''],
  ['applyPreAllocatedPayment', ''],
  ['listPreallocatedCustomers', ''],
  ['search', ''],
  ['getTasks', ''],
  ['getUserPin', ''],
  ['borrowingProducts', ''],
  ['previewUpdateLoanRepaymentPlan', ''],
  ['getStaffUsername', ''],
  ['monzoFlexAccounts', ''],
  ['bizopsTask', ''],
  ['searchCompanyInFca', ''],
  ['storeDocuments', ''],
  ['listTokens', ''],
  ['orderCard', ''],
  ['GetAccountDetails', ''],
  ['readDynamicFormResponse', ''],
  ['customerExitsByUserId', ''],
  ['getCallFlowCustomerDetails', ''],
  ['accountBanStatus', ''],
  ['updateProductLabel', ''],
  ['sendBizopsEmail', ''],
  ['getChatTaskForUser', ''],
  ['fasterPaymentsFraudCasesByAccountID', ''],
  ['getDuplicateUserIdsForUser', ''],
  ['transactions', ''],
  ['transactionsById', ''],
  ['trackAnalyticsEvent', ''],
  ['WorkflowTaskType', ''],
  ['approveTransactionDisputeForm', ''],
  ['previewCreateCollectionsPromise', ''],
  ['legalNameReviewTask', ''],
  ['getBusinessCorporateStructure', ''],
  ['listCustomerRiskAssessments', ''],
  ['transactionsAndPotsByAccountId', ''],
  ['account', ''],
  ['neonCardStatus', ''],
  ['createDisputeNoteFromTransactions', ''],
  ['createInsolvency', ''],
  ['insolvenciesData', ''],
  ['GetChequeFraudReport', ''],
  ['createFormResponse', ''],
  ['getSuspectedFraudReview', ''],
  ['getAccountTakeoverUserDeviceRisks', ''],
  ['customerSars', ''],
  ['withdrawFasterPaymentsFraudCase', ''],
  ['subjectExit', ''],
  ['rewardsStatusByTransactionId', ''],
  ['updateTransactionsInDisputeForm', ''],
  ['listForUser', ''],
  ['allowancesByCustomerId', ''],
  ['feesByCustomerId', ''],
  ['transactionDisputeForms', ''],
  ['paidEligibility', ''],
  ['allPaidPlans', ''],
  ['fraudProtectInvestigation', ''],
  ['flexAccounts', ''],
  ['collectionsPromisesByUser', ''],
  ['budgetAssessmentsByUserId', ''],
  ['staffProfile', ''],
  ['setUserPresenceFromToggle', ''],
  ['deletePreAllocatedProductSegment', ''],
  ['updateRewardsEscalation', ''],
  ['customersByMultipleFields', ''],
  ['dataQualityReview', ''],
  ['submitQuestionResponses', ''],
  ['createCustomerExitFull', ''],
  ['getReview', ''],
  ['customerSearch', ''],
  ['listMonzoFlexForbearances', ''],
  ['listOfficers', ''],
  ['ukfinanceBPSCaseByID', ''],
  ['completePhoneNumberReviewTask', ''],
  ['identitySubmissions', ''],
  ['approveFasterPaymentsFraudCase', ''],
  ['getDisputeTransactionsIds', ''],
])

const getSamplingRate = (operationName: string): number => {
  if (highFrequencyValues.has(operationName)) {
    return SAMPLING_RATE_BY_FREQUENCY.HIGH
  } else if (mediumFrequencyValues.has(operationName)) {
    return SAMPLING_RATE_BY_FREQUENCY.MEDIUM
  } else if (lowFrequencyValues.has(operationName)) {
    return SAMPLING_RATE_BY_FREQUENCY.LOW
  } else {
    return SAMPLING_RATE_BY_FREQUENCY.NO_SAMPLING
  }
}

export const sampleNoisyGraphqlOperation: SentryEventFilter = ({
  operationName,
}) => {
  if (!operationName || !envIsProd()) {
    return false
  }

  const samplingRate = getSamplingRate(operationName)

  return Math.random() > samplingRate
}
