import { ApolloError } from '@apollo/client'
import { Scope } from '@sentry/types'
import { print } from 'graphql'

import { Sentry } from '../index'
import {
  filterIgnoredStatusCode,
  filterNetworkFailedToFetch,
  filterPermissionIssues,
  sampleNoisyGraphqlOperation,
} from './filters'
import type {
  NormalisedResponse,
  Operation,
  Options,
  SentryEventFilters,
} from './types'

enum SENTRY_LABELS {
  DESCRIPTION = 'Description',
  FINGERPRINT_PREFIX = 'graphql-operation:',
  OPERATION = 'Operation',
  TAG_OPERATION_NAME = 'operation.name',
  TAG_OPERATION_STATUS_CODE = 'operation.statusCode',
  TAG_OPERATION_REQUEST_ORIGIN_APP = 'origin.app',
  TAG_OPERATION_REQUEST_ORIGIN_WEBLET = 'origin.weblet',
  TAG_OPERATION_REQUEST_ORIGIN_LIB = 'origin.lib',
  TAG_TEMPLATE_VERSION = 'error.version',
}

const STATUS_CODE_UNKNOWN = '(unknown)'
const APP_NAME_UNKNOWN = '(unknown)'
const OPERATION_NAME_UNKNOWN = '(anonymous operation)'
const TEMPLATE_VERSION = '2.0'

const defaultSentryEventFilters = {
  noisyOperations: sampleNoisyGraphqlOperation,
  statusCode: filterIgnoredStatusCode,
  networkFailedToFetch: filterNetworkFailedToFetch,
  permissionIssues: filterPermissionIssues,
} as const

let activeEventFilters: SentryEventFilters = {}

const setupSentryEventFilters = ({
  useFilters = [],
  customFilters = {},
}: {
  useFilters?: (keyof typeof defaultSentryEventFilters)[]
  customFilters?: SentryEventFilters
} = {}) => {
  const standardFilters = useFilters.reduce<SentryEventFilters>(
    (selectedFilters, filter) => {
      selectedFilters[filter] = defaultSentryEventFilters[filter]

      return selectedFilters
    },
    {},
  )

  activeEventFilters = {
    ...standardFilters,
    ...customFilters,
  }
}

const shouldFilterSentryEvents = (response: NormalisedResponse) => {
  const shouldIgnore = Object.values(activeEventFilters)
    .map((filter) => {
      return filter ? filter(response) : false
    })
    .includes(true)

  return shouldIgnore
}

const getStatusCode = (apolloError: ApolloError) => {
  let statusCode = STATUS_CODE_UNKNOWN

  if (apolloError.networkError && 'statusCode' in apolloError.networkError) {
    statusCode = `${apolloError.networkError.statusCode}`
  }

  return statusCode
}

const getAppName = () => window.Monzo?.appName || APP_NAME_UNKNOWN

const getTags = ({
  statusCode,
  operationName,
}: {
  operationName: string
  statusCode: string
}) => {
  const tags: Record<string, string> = {
    [SENTRY_LABELS.TAG_OPERATION_STATUS_CODE]: statusCode
      ? statusCode
      : STATUS_CODE_UNKNOWN,
    [SENTRY_LABELS.TAG_OPERATION_NAME]: operationName,
    [SENTRY_LABELS.TAG_TEMPLATE_VERSION]: TEMPLATE_VERSION,
    [SENTRY_LABELS.TAG_OPERATION_REQUEST_ORIGIN_APP]: getAppName(),
  }

  return tags
}

const getOperationName = (operation: Operation): string => {
  if (typeof operation === 'string') {
    return operation
  }

  const pattern = /\b(query|mutation)\s+(\w+)/
  const matches = print(operation).match(pattern)
  return matches && matches[2] ? matches[2] : OPERATION_NAME_UNKNOWN
}

const logGraphqlError = ({
  apolloError,
  options,
  operation,
}: {
  apolloError?: ApolloError
  options: Options
  operation: Operation
}) => {
  if (!apolloError) {
    return
  }

  const statusCode = getStatusCode(apolloError)
  const operationName = getOperationName(operation)

  if (shouldFilterSentryEvents({ apolloError, statusCode, operationName })) {
    return
  }

  if (Sentry != null) {
    Sentry.withScope((scope: Scope) => {
      scope.setTags(getTags({ operationName, statusCode }))
      scope.setFingerprint([
        `${[SENTRY_LABELS.FINGERPRINT_PREFIX]}${operationName}`,
      ])

      scope.setContext('Details', {
        [SENTRY_LABELS.DESCRIPTION]: options?.description,
      })

      Sentry.captureException(apolloError)
    })
  }
}

export {
  SENTRY_LABELS,
  activeEventFilters,
  logGraphqlError,
  setupSentryEventFilters,
}
