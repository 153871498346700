import * as SentryBrowserImport from '@sentry/browser'
import type {
  Breadcrumb,
  BreadcrumbHint,
  EventHint,
  Scope,
  Event as SentryEvent,
} from '@sentry/types'
import { $ReadOnly } from 'utility-types'

type SetupOptions = $ReadOnly<{
  gitCommit?: string | null
  sentryDSN?: string | null
  environment?: string
  beforeSend?: (
    event: SentryEvent,
    hint?: EventHint,
  ) => Promise<SentryEvent | null> | SentryEvent | null
  beforeBreadcrumb?: (
    breadcrumb: Breadcrumb,
    hint?: BreadcrumbHint,
  ) => Breadcrumb | void
  ignoreErrors?: string[]
  componentName?: string
}>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export let Sentry: any

export type { Scope }

export class HTTPError extends Error {
  response: Response
  status: number
  body?: Record<string, unknown> | string | null

  constructor(response: Response, body?: Record<string, unknown> | string) {
    let message = body

    if (typeof message !== 'string') {
      if (
        typeof message === 'object' &&
        message != null &&
        'message' in message &&
        typeof message['message'] === 'string' &&
        message['message'] !== ''
      ) {
        message = message['message']
      } else {
        message = `HTTP error ${response.status}`
      }
    }

    super(message)
    this.response = response
    this.status = response.status
    this.body = body

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, HTTPError)
    }
  }
}

/*
 * Allows us to use this library with isomorphic Next.JS apps,
 * by passing in the `@sentry/nextjs` library.
 */
export function initWithSentryLibrary(sentryImpl: unknown) {
  if (Sentry == null) {
    Sentry = sentryImpl
  }
}

/*
 * Function to configure error logging to Sentry in your app.
 * The beforeSend and beforeBreadcrumb functions allow us to sanitize what we send to Sentry.
 * These are partially applied functions, so must be called when passed in as arguments here - they return a function which takes
 * an event or breadcrumb.
 * Please see ./sanitizers.js for more info.
 */
export function setup({
  gitCommit,
  sentryDSN,
  beforeSend,
  beforeBreadcrumb,
  environment,
  ignoreErrors,
  componentName,
}: SetupOptions) {
  if (sentryDSN == null || sentryDSN === '') {
    console.warn('Sentry: not loading, missing sentryDSN in env.js')
  } else {
    Sentry = SentryBrowserImport
    Sentry.init({
      dsn: sentryDSN,
      release: gitCommit ?? '',
      beforeSend,
      beforeBreadcrumb,
      environment: environment ?? undefined,
      ignoreErrors,
      initialScope: componentName
        ? { tags: { component_name: componentName } }
        : undefined,
    })

    if (typeof window !== 'undefined') {
      window.onunhandledrejection = captureUnhandledPromiseRejection
    }
  }
}

export function captureUnhandledPromiseRejection(e: PromiseRejectionEvent) {
  captureException(e.reason)
}

export type ModifyScopeFunc = (scope: Scope) => void

const isBizops = (): boolean => window.Monzo?.appName === 'bizops'

const shouldIgnoreError = (err: Error) =>
  err instanceof HTTPError &&
  ((err.status > 399 && err.status < 404) || err.status > 499) &&
  isBizops()

export function captureExceptionWithInfo(
  err: Error,
  info: Record<string, unknown> | React.ErrorInfo,
  modifyScope?: ModifyScopeFunc,
) {
  if (shouldIgnoreError(err)) {
    return
  }

  if (Sentry != null) {
    Sentry.withScope((scope: Scope) => {
      scope.setExtras(info as Record<string, unknown>)

      if (modifyScope != null) {
        modifyScope(scope)
      }

      Sentry.captureException(err)
    })
  }
}
export function captureException(
  err: Error | HTTPError,
  modifyScope?: ModifyScopeFunc,
) {
  if (shouldIgnoreError(err)) {
    return
  }

  if (Sentry != null) {
    Sentry.withScope((scope: Scope) => {
      if (modifyScope != null) {
        modifyScope(scope)
      }

      Sentry.captureException(err)
    })
  }
}
export function captureBreadcrumb(bc: Breadcrumb) {
  if (Sentry != null) Sentry.addBreadcrumb(bc)
}
export { sanitizeBreadcrumb, sanitizeEvent } from './sanitizers'

export {
  logGraphqlError,
  setupSentryEventFilters,
} from './logGraphqlError/logGraphqlError'

export { shouldSkipSentryEvent } from './logGraphqlError/temporary/sentryContext'

export type { Options } from './logGraphqlError/types'
